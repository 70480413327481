export default function () {
  // Smooth scroll to all anchor links
  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth',
      });
    });
  });

  // Prevent hard reload when clicking on links that link to current page
  const links = document.querySelectorAll('a[href]');
  const cbk = function (e) {
    if (e.currentTarget.href === window.location.href) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  links.forEach((link) => link.addEventListener('click', cbk));

  //
  if (window.location.href.includes('#')) {
    const el = document.querySelector(`#${window.location.href.split('#')[1]}`);
    el.scrollIntoView();
  }
}
